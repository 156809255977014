import React from 'react'
import { Avatar, Image, Space } from 'antd';
import { Link } from 'react-router-dom';
import SignOutButton from './SignOutButton';

function Header({ user }) {
    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    <div className="logo">
                        <span style={{ color: '#fff', cursor:'auto' }}>
                            {process.env.REACT_APP_TITLE}
                        </span>
                    </div>
                    <span style={{ color: 'rgb(255, 255, 255)', fontSize: '10px', float: 'right' }}>Powered by GPT-4o mini</span>
                </div>
                <div className="topRight">
                    <div className="topbarIconContainer">
                        <i className="bx bx-bulb" style={{ fontSize: '1.5rem' }} />
                        <Link target="_blank" to="https://dupont.sharepoint.com/teams/CSSOKnowledgeCtr/SitePages/Support.aspx"><span>Learn more & submit ideas</span></Link>
                    </div>

                    <div className="topbarIconContainer" style={{cursor:'auto'}}>
                        <Space>
                            {user.avatar === null ?
                                <Avatar
                                    style={{ backgroundColor: "#fde3cf", color: "#f56a00", borderColor: "#E1E1E1" }}
                                    src={null}
                                >
                                    <span style={{ fontWeight: 700, fontSize: 12 }}>{user.firstname[0]}{user.lastname[0]}</span>
                                </Avatar>

                                : <Avatar
                                    // shape="square"
                                    style={{ borderColor: "#E1E1E1" }}
                                    src={user.avatar}
                                />
                            }

                            <span>{user.fullname}</span>
                            
                        </Space>
                    </div>

                    <div className="topbarIconContainer">
                        <SignOutButton />
                    </div>

                    <div className="topbarIconContainer" style={{cursor:'auto'}}>
                        <Image
                            height={24}
                            src='../assets/DuPont_tm_w.svg'
                            preview={false}
                        />
                    </div>

                </div>
            </div>
        </div >
    );
}

export default Header